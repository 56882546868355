import { useEffect, useMemo, useState } from 'react';
import { Text } from '@fluentui/react-components';
import { LoadingScreen } from '../../common/LoadingScreen';
import * as api from '../../api';
import { t } from '@lingui/macro';
import { EidEasyProvider, Providers } from './Providers';
import { useProviderSelectionContext } from '.';

export const ProvidersList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [providersCountryResponse, setProvidersCountryResponse] = useState<any>({});
  const [providersForSession, setProvidersForSession] = useState<any>([]);

  const { features, signing, country, isXML, xadesFormat } = useProviderSelectionContext();

  useEffect(() => {
    (async () => {
      const providersCountrySigning = await api.configurationProviders.getProvidersCountrySigning();
      setProvidersCountryResponse(providersCountrySigning);

      const sessionProviders = await api.signing.getAvailableProviders();
      setProvidersForSession(sessionProviders);

      setLoading(false);
    })();
  }, []);

  const availableProviders = useMemo(() => {
    let providersInCountry = providersCountryResponse?.countries?.[country] || [];

    const worldwideProviders = providersCountryResponse?.countries?.['WORLD'];
    if (worldwideProviders) {
      providersInCountry = providersInCountry.concat(worldwideProviders);
    }

    if (providersInCountry) {
      const featureFlagEnabledProviders = providersInCountry.filter((provider: string) => {
        if (provider.startsWith('EidEasy:')) {
          return providersForSession.includes('EidEasy');
        }
        return providersForSession.includes(provider);
      });

      if (!features.XmlSigning && isXML) {
        // the file is xml but xml signing is disabled via the feature flag
        return [];
      }
      if (features.XmlSigning && isXML) {
        if (xadesFormat) {
          return featureFlagEnabledProviders.filter((x: string) =>
            signing.xmlSupport[x].supportsXml && signing.xmlSupport[x].supportedFormats.includes(xadesFormat)
          );
        } else {
          return featureFlagEnabledProviders.filter((x: string) => !!signing.xmlSupport[x].supportsXml);
        }
      }

      return featureFlagEnabledProviders;
    }

    return [];
  }, [providersCountryResponse, providersForSession, country, features, isXML, signing.xmlSupport]);

  const providersCount = useMemo(() => Object.values(availableProviders).length, [availableProviders]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Text className="provider-selection__available-providers">
          {t({ id: 'ProviderSelection.Signing.ProvidersCount', values: { providersCount: providersCount } })}
        </Text>
      </div>
      <div>
        {availableProviders.map((provider: string) => {
          if (provider in Providers) {
            const RenderProvider = Providers[provider];
            return <RenderProvider key={provider} />;
          } else if (provider.startsWith('EidEasy:')) {
            return <EidEasyProvider key={provider} provider={provider} />;
          }
          return null;
        })}
      </div>
    </>
  );
};
