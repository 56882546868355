import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { LinkToPrivacyPolicy } from '../../common/PrivacyPolicy';
import { ActionableSection } from '../../common/ActionableSection';
import { MszafirIcon } from '../../common/Icons';
import { WizardBox } from '../../common/WizardBox';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons/ButtonBack';
import './Mszafir.scss';

interface MszafirIntroProps {
  fileName: string;
  onSelectCertificate: (certType: any) => void;
  onShowPrivacyPolicy: () => void;
  onBack: () => void;
  onCancel: () => void;
  features?: any;
}
export const MszafirIntro = ({
  fileName,
  onSelectCertificate,
  onShowPrivacyPolicy,
  onBack,
  onCancel,
  features,
}: MszafirIntroProps) => {
  const headerContent = t({ id: 'Providers.Mszafir.Certs.Header', values: { fileName } });

  const selections = [
    ['MszafirModeShortTerm', 'UseShortTermCertificate', 'Providers.Mszafir.Certs.ShortTerm'],
    ['MszafirModeLongTerm', 'GenerateLongTermCertificate', 'Providers.Mszafir.Certs.LongTerm'],
    ['MszafirModeExisting', 'UseLongTermCertificate', 'Providers.Mszafir.Certs.Existing'],
  ]
    .filter((sel) => features[sel[0]])
    .map((sel) => (
      <ActionableSection
        key={sel[1]}
        icon={<MszafirIcon />}
        action={t({ id: 'Common.Proceed' })}
        onClick={() => onSelectCertificate(sel[1])}
      >
        <Text weight="semibold">{t({ id: sel[2] })}</Text>
      </ActionableSection>
    ));

  return (
    <WizardBox className="mszafir-window">
      <div>
        <Text as="h1" weight="bold" className="mszafir-window__header-with-filename">
          {headerContent}
        </Text>
      </div>
      <div>
        <div>{selections}</div>
        <div>
          <LinkToPrivacyPolicy onShowPrivacyPolicy={onShowPrivacyPolicy} />
        </div>
      </div>
      <div>
        <ButtonBack onClick={onBack} />
        <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
      </div>
    </WizardBox>
  );
};
